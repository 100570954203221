import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { disableRefreshingDuringInstallation, shouldNotInstallProfileCard } from '../../../../utils/experiments';
import { getIsClassicEditor } from '../../../services/applicationState';
import { toMonitored } from '../../../../utils/monitoring';
import { uninstallMembersArea } from '../services/uninstall';
import { addGlobalController } from '../services/controllers';
import { addLoginMenus, addMembersSubMenu } from '../services/menus';
import { addMyAccountMenuItemToMembersAreaMenus } from '../services/menu-items';
import { addMembersAreaPage, hideProfileCard, refreshMemberPage } from '../services/members-area-page';
import { navigateToMembersAreaPage } from '../services/navigation';

const isStandaloneInstallation = (options: EditorReadyOptions) => !options.origin.info?.appDefinitionId;

const shouldNavigateAfterInstallation = async (options: EditorReadyOptions) => {
  // Studio will navigate itself while installing
  if (!getIsClassicEditor()) {
    return false;
  }

  // Navigate to MA only when standalone / no other app invoked the installation
  return isStandaloneInstallation(options);
};

const monitoredUninstallMembersArea = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.install.uninstallMembersArea', () => uninstallMembersArea(editorSDK));
  } catch (e) {}
};

const monitoredAddGlobalController = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.install.addGlobalController', () => addGlobalController(editorSDK));
  } catch (error: any) {
    if (error?.message?.includes?.('already exists')) {
      return;
    }

    throw error;
  }
};

export const installMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  try {
    await monitoredAddGlobalController(editorSDK);
    await Promise.all([addMembersSubMenu(editorSDK), addLoginMenus(editorSDK, options), addMembersAreaPage(editorSDK)]);

    if (await shouldNotInstallProfileCard()) {
      await addMyAccountMenuItemToMembersAreaMenus(editorSDK, options);
    } else {
      await Promise.all([addMyAccountMenuItemToMembersAreaMenus(editorSDK, options), hideProfileCard(editorSDK)]);
    }

    if ((await disableRefreshingDuringInstallation()) && isStandaloneInstallation(options)) {
      await refreshMemberPage(editorSDK);
    }

    if (await shouldNavigateAfterInstallation(options)) {
      await navigateToMembersAreaPage(editorSDK);
    }
  } catch (e) {
    await monitoredUninstallMembersArea(editorSDK);
    throw e;
  }
};
