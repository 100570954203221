import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function shouldFixCorruptedRouterPatterns() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.FixCorruptedRouterPatterns') === 'true';
}

async function shouldDeleteMobileComponents() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.handleMobileComponentsDeletion') === 'true';
}

async function shouldFixLoginBarResponsiveLayout() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.fixLoginBarResponsiveLayout') === 'true';
}

async function shouldEnableSignUpPrivacyNoteType() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.ident.EnableSignUpPrivacyNoteType') === 'true';
}

async function shouldEnableTimeoutLogs() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.enableTimeoutLogs') === 'true';
}

async function shouldAllowDeletionOfBrokenApp() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AllowDeletionOfBrokenApp') === 'true';
}

async function shouldNotAllowStartAfterStop() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.NoStartAfterStop') === 'true';
}

async function shouldEnableSeoPanelChanges() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.seoPanelChanges') === 'true';
}

async function shouldUseNewAppInstallCheck() {
  try {
    await maybeConductExperiments();
  } catch {}

  return conductedExperiments.get('specs.membersArea.UseNewAppInstallCheck') === 'true';
}

async function shouldEnableCustomPagesInV2() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersAreaV2.enableCustomPages') === 'true';
}

async function shouldAddLoginBarMenuRefsToDefinitions() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.LoginBarAddMenuRefsToDefinitions') === 'true';
}

async function shouldUseNewRoutesService() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.UseNewRoutesService') === 'true';
}

async function shouldEnableMigration() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.migrateToV2') === 'true';
}

async function shouldInstallMembersAreaV2() {
  const experimentsV2 = new Experiments({ scope: 'members-area-v2' });
  await experimentsV2.conduct('specs.membersAreaV2.BoBInstallation', 'false');
  return experimentsV2.get('specs.membersAreaV2.BoBInstallation') === 'true';
}

async function shouldNotInstallProfileCard() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.DoNotInitiallyInstallProfileCard') === 'true';
}

async function disableRefreshingDuringInstallation() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.DisableLivePreviewRefreshes') === 'true';
}

async function shouldUseGlobalControllerRoutes() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.GetRoutesUseGlobal') === 'true';
}

async function shouldOptimizeVerticalDeletion() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.OptimizeVerticalDeletion') === 'true';
}

async function shouldEnableV2SilentInstall() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EnableV2SilentInstall') === 'true';
}

export {
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  shouldFixCorruptedRouterPatterns,
  shouldDeleteMobileComponents,
  shouldFixLoginBarResponsiveLayout,
  shouldEnableSignUpPrivacyNoteType,
  shouldEnableTimeoutLogs,
  shouldAllowDeletionOfBrokenApp,
  shouldNotAllowStartAfterStop,
  shouldEnableSeoPanelChanges,
  shouldUseNewAppInstallCheck,
  shouldEnableCustomPagesInV2,
  shouldAddLoginBarMenuRefsToDefinitions,
  shouldUseNewRoutesService,
  shouldEnableMigration,
  shouldInstallMembersAreaV2,
  shouldNotInstallProfileCard,
  disableRefreshingDuringInstallation,
  shouldUseGlobalControllerRoutes,
  shouldOptimizeVerticalDeletion,
  shouldEnableV2SilentInstall,
};
